<script lang="ts" setup>
defineProps<{
    error?: string;
    text?: string;
}>();

const handleReloadPage = () => {
    reloadNuxtApp();
};
</script>

<template>
    <div class="loader">
        <div class="loader__inner" />
        <div
            v-if="error"
            class="loader__text--error"
        >
            {{ error }}
        </div>
        <div
            v-if="text"
            class="loader__text"
        >
            {{ text }}
        </div>
        <CommonButtonCornered
            v-if="error"
            class="loader__refresh-btn"
            @click="handleReloadPage"
        >
            Refresh
        </CommonButtonCornered>
    </div>
</template>

<style lang="scss" scoped>
.loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: size(15px);

    &__refresh-btn {
        width: size(126px);
        height: size(30px);
        background-color: $color-gray;
    }

    &__inner {
        width: 65px;
        aspect-ratio: 1;
        position: relative;
    }

    &__inner:before,
    &__inner:after {
        content: "";
        position: absolute;
        border-radius: size(50px);
        box-shadow: 0 0 0 size(3px) inset $color-primary;
        animation: loader-anim 2.5s infinite;
    }

    &__inner:after {
        animation-delay: -1.25s;
    }

    &__text {
        text-align: center;
        font-size: size(26px);
        color: $color-primary;
        font-weight: 600;

        &--error {
            color: #ff0000;
        }
    }
}

@keyframes loader-anim {
    0% {
        inset: 0 35px 35px 0;
    }

    12.5% {
        inset: 0 35px 0 0;
    }

    25% {
        inset: 35px 35px 0 0;
    }

    37.5% {
        inset: 35px 0 0 0;
    }

    50% {
        inset: 35px 0 0 35px;
    }

    62.5% {
        inset: 0 0 0 35px;
    }

    75% {
        inset: 0 0 35px 35px;
    }

    87.5% {
        inset: 0 0 35px 0;
    }

    100% {
        inset: 0 35px 35px 0;
    }
}
</style>
